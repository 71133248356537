<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:body>
            <p v-show="!propOpenedInModal">{{cvCardSubHeader}}</p>
            <form action="#" v-if="vmSpcm_milestonesFormData && Object.keys(vmSpcm_milestonesFormData).length > 0">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="validationms_student_country ">
                    {{cvMsStudentCountry}}</label
                  >
                  <input
                    v-model="vmSpcm_milestonesFormData.ms_student_country "
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationms_board">
                    {{cvMsBoardLabel}}</label
                  >
                  <input
                    v-model="vmSpcm_milestonesFormData.ms_board"
                    type="text"
                    class="form-control"
                    required
                  />
                  <small class="input_ex"> Ex- ALL, CBSE, ICSE etc </small>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationms_class">
                    {{cvMsClassLabel}}</label
                  >
                  <select v-model="vmSpcm_milestonesFormData.ms_class" class="form-control">
                    <option value="">All</option>
                    <option value="12">12</option>
                    <option value="11">11</option>
                    <option value="10">10</option>
                    <option value="9">9</option>
                    <option value="8">8</option>
                  </select>
                  <small class="input_ex"> Ex- Comma separated 11,12 </small>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationms_univ_partnership_type">
                    {{cvMsUnivPartnershipTypeLabel}}</label
                  >
                  <input
                    v-model="vmSpcm_milestonesFormData.ms_univ_partnership_type"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationms_country_strategy">
                    {{cvMsCountryStrategyLabel}}</label
                  >
                  <select v-model="vmSpcm_milestonesFormData.ms_country_strategy" class="form-control">
                    <option value="USA">USA</option>
                    <option value="UK">UK</option>
                    <option value="CANADA">CANADA</option>
                    <option value="OXBRIDGE">OXBRIDGE</option>
                    <option value="SINGAPORE">SINGAPORE</option>
                    <option value="AUSTRALIA">AUSTRALIA</option>
                  </select>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationms_months">
                    {{cvMsMonthsLabel}}</label
                  >
                  <input
                    v-model="vmSpcm_milestonesFormData.ms_months"
                    type="text"
                    class="form-control"
                    required
                  />
                  <small class="input_ex"> Ex- Have to be Numeric, Comma Separated. from 1 to 12 </small>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationms_title">
                    {{cvMsTitleLabel}}</label
                  >
                  <input
                    v-model="vmSpcm_milestonesFormData.ms_title"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationms_desc">
                    {{cvMsDescLabel}}</label
                  >
                  <textarea
                  v-model="vmSpcm_milestonesFormData.ms_desc"
                    type="text"
                    class="form-control textarea"
                    required>
                  </textarea>
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationms_reading_reco">
                    {{cvMsReadingRecoLabel}}</label
                  >
                  <input
                    v-model="vmSpcm_milestonesFormData.ms_reading_reco"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationms_reading_reco_url">
                    {{cvMsReadingRecoUrlLabel}}</label
                  >
                  <input
                    v-model="vmSpcm_milestonesFormData.ms_reading_reco_url"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationms_color_code">
                    {{cvMsColorCodeLabel}}</label
                  >
                  <input
                    v-model="vmSpcm_milestonesFormData.ms_color_code"
                    type="text"
                    class="form-control"
                    required
                  />
                  <small class="input_ex"> Ex- Red, Orange or #EFDCEA </small>
                </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="spcm_milestonesEdit()">{{cvSubmitBtn}}</button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { spcmMilestones } from "../../../FackApi/api/Spcmmilestones.js"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"

export default {
  name: "spcmMilestonesEdit",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propSpcm_milestonesObj: {
      type: Object,
      default: function () {
        return {
          "ms_student_country ": "",
          "ms_board": "",
          "ms_class": "",
          "ms_univ_partnership_type": "",
          "ms_country_strategy": "",
          "ms_months": "",
          "ms_title": "",
          "ms_desc": "",
          "ms_reading_reco": "",
          "ms_reading_reco_url": "",
          "ms_color_code": ""
        }
      }
    }
  },
  data () {
    return {
      cvCardTitle: "Edit Counselling Milestones",
      cvCardSubHeader: "Edit Counselling Milestones",
      cvSubmitBtn: "Edit",
      cvMsStudentCountry: "student country ",
      cvMsBoardLabel: "board",
      cvMsClassLabel: "class",
      cvMsUnivPartnershipTypeLabel: "univ partnership type",
      cvMsCountryStrategyLabel: "country strategy",
      cvMsMonthsLabel: "months",
      cvMsTitleLabel: "title",
      cvMsDescLabel: "desc",
      cvMsReadingRecoLabel: "reading reco",
      cvMsReadingRecoUrlLabel: "reading reco url",
      cvMsColorCodeLabel: "color code",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Spcm_milestones Updated",
      vmSpcm_milestonesFormData: {}
    }
  },
  mounted () {
    socialvue.index()
    this.spcm_milestonesView()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmSpcm_milestonesFormData) {
          if (!this.vmSpcm_milestonesFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * spcm_milestonesView
     */
    async spcm_milestonesView () {
      try {
        if (this.propOpenedInModal) {
          this.vmSpcm_milestonesFormData = this.propSpcm_milestonesObj
        }
        else {
          let spcmMilestonesId = this.$route.params.ms_id
          let spcmMilestonesViewResp = await spcmMilestones.spcm_milestonesView(this, spcmMilestonesId)

          if (spcmMilestonesViewResp && spcmMilestonesViewResp.resp_status) {
            this.vmSpcm_milestonesFormData = spcmMilestonesViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at spcm_milestonesView() and Exception:", err.message)
      }
    },
    /**
     * spcm_milestonesEdit
     */
    async spcm_milestonesEdit () {
      try {
        // if (!this.validate()) {
        //   return false
        // }
        this.cvLoadingStatus = true
        let spcmMilestonesAddResp = await spcmMilestones.spcm_milestonesEdit(this, this.vmSpcm_milestonesFormData)
        await ApiResponse.responseMessageDisplay(this, spcmMilestonesAddResp)

        if (spcmMilestonesAddResp && !spcmMilestonesAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseSpcm_milestonesEditModal", this.vmSpcm_milestonesFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at spcm_milestonesEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
<style scoped>
 textarea{
    height: 195px !important;
    overflow-y: scroll;
    line-height: 18px !important;
  }
</style>
